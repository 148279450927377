<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>{{ $t('upload') }}</v-card-title>

                    <v-card-text>
                        <vue-dropzone ref="dropzone" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState} from 'vuex'

    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    export default {
        name: 'Upload',

        components: {
            vueDropzone: vue2Dropzone
        },

        computed: {
            ...mapState(['isAdmin'])
        },

        created() {
            // Route guard
            if (!this.isAdmin) {
                this.$store.dispatch('logUserOut')
                this.$router.push('/login')
            }
        },

        data() {
            return {
                dropzoneOptions: {
                    url: "/upload",
                    acceptedFiles: "image/*,application/pdf",
                    thumbnailWidth: 150,
                    thumbnailHeight: 150,
                    addRemoveLinks: true,
                    dictDefaultMessage: this.$t('select_file'),
                },
            }
        },

        methods: {
        },
    };
</script>
